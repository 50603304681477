import { WidgetBuilder } from '@wix/yoshi-flow-editor';
import { EditorScriptContext } from '../types';
import { BM_URL_MANAGE_GFPP, LOYALTY_PAGE_HELP_ID } from '../constants';

export const configureLoyaltyPageWidget = (
  { flowAPI, editorSDK }: EditorScriptContext,
  widgetBuilder: WidgetBuilder,
) => {
  const { t } = flowAPI.translations;

  widgetBuilder
    .gfpp()
    .set('mainAction2', {
      label: t('app.gfpp.manage-program'),
      onClick: async () => {
        await editorSDK.editor.openDashboardPanel('', {
          url: BM_URL_MANAGE_GFPP,
          closeOtherPanels: false,
        });
      },
    })
    .set('upgrade', { behavior: 'SITE_UPGRADE' })
    .set('help', {
      id: LOYALTY_PAGE_HELP_ID,
    });
};
